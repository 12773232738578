<template>
  <div
    class="background"
    style="
      width: 100vw;
      height: calc(100vh - 1px);
      top: 0;
      left: 0;
      position: fixed;
    "
  >
    <span v-for="n in 20" :key="n"></span>
  </div>
</template>

<style></style>

<script>
export default {
  name: "background",

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.background {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 27, 88, 0.3);
}

$particleSize: 20vmin;
$animationDuration: 6s;
$amount: 50;
.background span {
  width: $particleSize;
  height: $particleSize;
  border-radius: $particleSize;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: $animationDuration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  $colors: (
    rgba(0, 27, 88, 0.5),
    rgba(202, 156, 29, 0.5),
    rgba(255, 255, 255, 0.5)
  );
  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      color: nth($colors, random(length($colors)));
      top: random(100) * 1%;
      left: random(100) * 1%;
      animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
      animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
      transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
      $blurRadius: (random() + 0.5) * $particleSize * 0.5;
      $x: if(random() > 0.5, -1, 1);
      box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
    }
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}
</style>
